<template>
  <b-modal
    id="modal-update-agency-fee"
    :title="$t('agency.fee.titleModalEdit')"
    :title-class="`text-airline font-weight-bolder ${isMobileView ? 'font-medium-2' : 'font-medium-4'}`"
    body-class="p-1"
    size="xl"
    centered
    no-close-on-backdrop
    hide-footer
    @show="showHandle"
  >
    <IAmOverlay
      :loading="!getAgenciesData"
      :spinner-variant="'info'"
      class="my-2 pb-1"
    >
      <div
        v-if="getAgenciesData && canUpdate"
        :class="`${getAgenciesData.applyAgencyFee ? 'mb-2' : 'my-2'} text-center`"
      >
        <BButton
          :variant="getAgenciesData.applyAgencyFee ? 'outline-danger' : 'outline-info'"
          class="font-medium-1"
          pill
          @click="changeAgenciesFeeConfig(getAgenciesData.id)"
        >
          {{ getAgenciesData.applyAgencyFee
            ? 'Huỷ phí xuất vé riêng cho đại lý này'
            : 'Bật phí xuất vé riêng cho đại lý này' }}
        </BButton>
      </div>

      <template v-if="getAgenciesData && getAgenciesData.applyAgencyFee">
        <ValidationObserver
          ref="refFormObserver"
        >
          <IAmOverlay :loading="!!loading && !serviceFeeConfigs">
            <div v-if="serviceFeeConfigs && serviceFeeConfigs.length">
              <!-- ANCHOR Bảng nội địa -->
              <h3>{{ $t('fee.domesticFee') }}</h3>
              <b-table
                :fields="tableColumns"
                striped
                bordered
                hover
                responsive
                :empty-text="$t('noMatchingResult')"
                :items="serviceFeeConfigs.filter(item => item.ticketType === 'INLAND')"
              >
                <template
                  v-for="column in tableColumns"
                  v-slot:[`head(${column.key})`]="data"
                >
                  <span
                    :key="column.label"
                    class="text-dark text-nowrap"
                  >
                    {{ $t(`fee.columns.${data.label}`) }}
                  </span>
                </template>
                <template #cell(airline)="{item}">
                  <div class="d-flex-center gap-1 font-weight-bolder">
                    <div
                      style="width: 50px"
                      class="d-flex-center"
                    >
                      <IAmLogoAirline
                        :airline="item.airline.toUpperCase() "
                        :height="30"
                      />
                    </div>
                    <span class="flex-1 fw-700">
                      {{ $te(`flight.airlines.${item.airline.toUpperCase()}`)
                        ? $t(`flight.airlines.${item.airline.toUpperCase()}`)
                        : '' }}
                      ({{ $te(`flight.sourceName.${item.airline.toUpperCase()}`)
                        ? $t(`flight.sourceName.${item.airline.toUpperCase()}`)
                        : item.airline.toUpperCase() }})
                    </span>
                  </div>
                </template>
                <template #cell(amount)="data">
                  <IAmInputMoney
                    id="amount-domesticFee"
                    :value-money.sync="data.item.amount"
                    :disabled="!canUpdate"
                    class="flex-grow-1"
                    style="min-width: 150px;"
                    :placeholder="$t('fee.placeholder.amount')"
                  />
                </template>
                <template #cell(feeType)="data">
                  <v-select
                    v-model="data.item.feeType"
                    :options="data.item.ticketType === 'INLAND' ? feeTypeOptions.filter(item => item.value === 'FLIGHT_ITINERARY') : feeTypeOptions"
                    :reduce="(val) => val.value"
                    :append-to-body="true"
                    :calculate-position="withPopper"
                    :clearable="false"
                    :disabled="!canUpdate"
                    :placeholder="$t('fee.placeholder.feeType')"
                  >
                    <template #option="{ label }">
                      <span class="d-one-line">
                        {{ label }}
                      </span>
                    </template>
                    <template #selected-option="{ label }">
                      <span class="d-one-line">
                        {{ label }}
                      </span>
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </template>
              </b-table>
              <!-- ANCHOR Bảng quốc tế -->
              <h3>{{ $t('fee.internationalFee') }}</h3>
              <b-table
                :fields="tableColumns"
                striped
                bordered
                hover
                responsive
                :empty-text="$t('noMatchingResult')"
                :items="serviceFeeConfigs.filter(item => item.ticketType === 'INTERNATIONAL')"
              >
                <template
                  v-for="column in tableColumns"
                  v-slot:[`head(${column.key})`]="data"
                >
                  <span
                    :key="column.label"
                    class="text-dark text-nowrap"
                  >
                    {{ $t(`fee.columns.${data.label}`) }}
                  </span>
                </template>
                <template #cell(airline)="{item}">
                  <div class="d-flex-center gap-1 font-weight-bolder">
                    <div
                      style="width: 50px"
                      class="d-flex-center"
                    >
                      <IAmLogoAirline
                        :airline="item.airline.toUpperCase() "
                        :height="30"
                      />
                    </div>
                    <span class="flex-1 fw-700">
                      {{ $te(`flight.airlines.${item.airline.toUpperCase()}`)
                        ? $t(`flight.airlines.${item.airline.toUpperCase()}`)
                        : '' }}
                      ({{ $te(`flight.sourceName.${item.airline.toUpperCase()}`)
                        ? $t(`flight.sourceName.${item.airline.toUpperCase()}`)
                        : item.airline.toUpperCase() }})
                    </span>
                  </div>
                </template>
                <template #cell(amount)="data">
                  <IAmInputMoney
                    id="amount-internationalFee"
                    :value-money.sync="data.item.amount"
                    style="min-width: 150px;"
                    class="flex-grow-1"
                    :disabled="!canUpdate"
                    :placeholder="$t('fee.placeholder.amount')"
                  />
                </template>
                <template #cell(feeType)="data">
                  <v-select
                    v-model="data.item.feeType"
                    :options="data.item.ticketType === 'INLAND' ? feeTypeOptions.filter(item => item.value === 'FLIGHT_ITINERARY') : feeTypeOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    :disabled="!canUpdate"
                    :append-to-body="true"
                    :calculate-position="withPopper"
                    :placeholder="$t('fee.placeholder.feeType')"
                  >
                    <template #option="{ label }">
                      <span class="d-one-line">
                        {{ label }}
                      </span>
                    </template>
                    <template #selected-option="{ label }">
                      <span class="d-one-line">
                        {{ label }}
                      </span>
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </template>
              </b-table>
            </div>
            <div class="d-flex-center">
              <BButton
                variant="outline-secondary"
                class="font-medium-1 mr-1"
                pill
                @click="handleExitModal"
              >
                {{ $t('modal.back') }}
              </BButton>
              <BButton
                v-if="canUpdate"
                class="btn-gradient font-medium-1"
                pill
                @click="createEmployeeProfileHandle"
              >
                {{ $t('agency.confirmChange') }}
              </BButton>
            </div>
          </IAmOverlay>
        </ValidationObserver>
      </template>
    </IAmOverlay>
  </b-modal>
</template>

<script>
import {
  BModal, BTable, BButton,
} from 'bootstrap-vue'
import { ref, toRefs, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import { cloneDeep, isEqual } from 'lodash'
import {
  ValidationObserver,
} from 'vee-validate'
import { createPopper } from '@popperjs/core'
import { v4 as uuidv4 } from 'uuid'

import { ticketTypeOptions, feeTypeOptions } from '@/constants/selectOptions'
import { apiAgencies } from '@/api/'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'

import useAgenciesHandle from '@agencies/useAgenciesHandle'

import useToast from '@useToast'

export default {
  name: 'ModalUpdateAgencyFee',

  components: {
    BModal,
    BTable,
    vSelect,
    BButton,
    ValidationObserver,

    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
  },
  props: {
    agenciesData: {
      type: [Object, null],
      default: null,
    },
    isEditPlace: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '170px'
      dropdownList.style.border = '1px solid #b8c2cc'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState } = formValidation()
    const { toastError, toastSuccess } = useToast()
    const getAgenciesData = toRefs(props).agenciesData
    const serviceFeeConfigs = ref()
    const loading = ref(false)

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const meData = computed(() => store.getters['userStore/getMeData'])
    const isType = computed(() => ['OPE', 'ADM'].includes(meData.value?.type))
    const getDistributors = computed(() => store.getters['globalConfig/getDistributors'])

    const canUpdate = computed(() => isRoleF2.value || (isRoleF1.value && isType.value))
    const tableColumns = [
      { label: 'airline', key: 'airline' },
      { label: 'feeType', key: 'feeType' },
      { label: 'amount', key: 'amount' },
    ]

    const {
      updateAgenciesFeeConfig,
    } = useAgenciesHandle()

    function showHandle() {
      const agencyFee = getAgenciesData.value?.agencyFee ? cloneDeep(getAgenciesData.value?.agencyFee) : []
      Object.keys(getDistributors.value).forEach(key => {
        getDistributors.value[key].forEach(sourceItem => {
          if (agencyFee.find(i => i.ticketType === key.toUpperCase() && i.airline === sourceItem)) return
          agencyFee.push({
            id: uuidv4(),
            airline: sourceItem,
            feeType: 'FLIGHT_ITINERARY',
            amount: 0,
            ticketType: key.toUpperCase(),
          })
        })
      })

      serviceFeeConfigs.value = agencyFee
    }

    function areArraysEqual(arr1, arr2) { // check change value before submit
      return isEqual(arr1, arr2)
    }

    async function createEmployeeProfileHandle() {
      const isValid = await refFormObserver.value.validate()
      if (isValid) {
        const result = areArraysEqual(getAgenciesData.value.agencyFee, serviceFeeConfigs.value)
        if (result) {
          toastError({ title: 'Vui lòng nhập, chỉnh sửa giá trị cần thay đổi.' })
          return
        }

        loading.value = true
        try {
          await apiAgencies.updateAgenciesFee(getAgenciesData.value?.id, { agencyFee: serviceFeeConfigs.value })
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.agency.edited',
          })
          this.$bvModal.hide('modal-update-agency-fee')
        } catch (error) {
          console.error(error)
          toastError({
            title: 'messagesList.error',
            content: `${error.message || error}`,
          })
        } finally {
          emit('refetchAgency')
          loading.value = false
        }
      }
    }

    function changeAgenciesFeeConfig(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatus'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            updateAgenciesFeeConfig(id)
              .then(res => {
                getAgenciesData.value.applyAgencyFee = res.applyAgencyFee
                if (!res.applyAgencyFee) this.$bvModal.hide('modal-update-agency-fee')
              })
              .finally(() => {
                if (props.isEditPlace) emit('refetchAgency')
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function handleExitModal() {
      this.$bvModal.hide('modal-update-agency-fee')
    }

    return {
      tableColumns,
      showHandle,
      getAgenciesData,
      ticketTypeOptions,
      serviceFeeConfigs,
      createEmployeeProfileHandle,
      changeAgenciesFeeConfig,
      loading,
      feeTypeOptions,
      refFormObserver,
      getValidationState,
      handleExitModal,
      canUpdate,
    }
  },
}
</script>

  <style lang="scss">
  input.vs__search {
    margin-top: 0;
  }
  </style>
