var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-update-agency-fee",
      "title": _vm.$t('agency.fee.titleModalEdit'),
      "title-class": "text-airline font-weight-bolder ".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-4'),
      "body-class": "p-1",
      "size": "xl",
      "centered": "",
      "no-close-on-backdrop": "",
      "hide-footer": ""
    },
    on: {
      "show": _vm.showHandle
    }
  }, [_c('IAmOverlay', {
    staticClass: "my-2 pb-1",
    attrs: {
      "loading": !_vm.getAgenciesData,
      "spinner-variant": 'info'
    }
  }, [_vm.getAgenciesData && _vm.canUpdate ? _c('div', {
    class: "".concat(_vm.getAgenciesData.applyAgencyFee ? 'mb-2' : 'my-2', " text-center")
  }, [_c('BButton', {
    staticClass: "font-medium-1",
    attrs: {
      "variant": _vm.getAgenciesData.applyAgencyFee ? 'outline-danger' : 'outline-info',
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeAgenciesFeeConfig(_vm.getAgenciesData.id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getAgenciesData.applyAgencyFee ? 'Huỷ phí xuất vé riêng cho đại lý này' : 'Bật phí xuất vé riêng cho đại lý này') + " ")])], 1) : _vm._e(), _vm.getAgenciesData && _vm.getAgenciesData.applyAgencyFee ? [_c('ValidationObserver', {
    ref: "refFormObserver"
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": !!_vm.loading && !_vm.serviceFeeConfigs
    }
  }, [_vm.serviceFeeConfigs && _vm.serviceFeeConfigs.length ? _c('div', [_c('h3', [_vm._v(_vm._s(_vm.$t('fee.domesticFee')))]), _c('b-table', {
    attrs: {
      "fields": _vm.tableColumns,
      "striped": "",
      "bordered": "",
      "hover": "",
      "responsive": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "items": _vm.serviceFeeConfigs.filter(function (item) {
        return item.ticketType === 'INLAND';
      })
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("fee.columns.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(airline)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex-center gap-1 font-weight-bolder"
        }, [_c('div', {
          staticClass: "d-flex-center",
          staticStyle: {
            "width": "50px"
          }
        }, [_c('IAmLogoAirline', {
          attrs: {
            "airline": item.airline.toUpperCase(),
            "height": 30
          }
        })], 1), _c('span', {
          staticClass: "flex-1 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$te("flight.airlines.".concat(item.airline.toUpperCase())) ? _vm.$t("flight.airlines.".concat(item.airline.toUpperCase())) : '') + " (" + _vm._s(_vm.$te("flight.sourceName.".concat(item.airline.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(item.airline.toUpperCase())) : item.airline.toUpperCase()) + ") ")])])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(data) {
        return [_c('IAmInputMoney', {
          staticClass: "flex-grow-1",
          staticStyle: {
            "min-width": "150px"
          },
          attrs: {
            "id": "amount-domesticFee",
            "value-money": data.item.amount,
            "disabled": !_vm.canUpdate,
            "placeholder": _vm.$t('fee.placeholder.amount')
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              return _vm.$set(data.item, "amount", $event);
            },
            "update:value-money": function updateValueMoney($event) {
              return _vm.$set(data.item, "amount", $event);
            }
          }
        })];
      }
    }, {
      key: "cell(feeType)",
      fn: function fn(data) {
        return [_c('v-select', {
          attrs: {
            "options": data.item.ticketType === 'INLAND' ? _vm.feeTypeOptions.filter(function (item) {
              return item.value === 'FLIGHT_ITINERARY';
            }) : _vm.feeTypeOptions,
            "reduce": function reduce(val) {
              return val.value;
            },
            "append-to-body": true,
            "calculate-position": _vm.withPopper,
            "clearable": false,
            "disabled": !_vm.canUpdate,
            "placeholder": _vm.$t('fee.placeholder.feeType')
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref2) {
              var label = _ref2.label;
              return [_c('span', {
                staticClass: "d-one-line"
              }, [_vm._v(" " + _vm._s(label) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(_ref3) {
              var label = _ref3.label;
              return [_c('span', {
                staticClass: "d-one-line"
              }, [_vm._v(" " + _vm._s(label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: data.item.feeType,
            callback: function callback($$v) {
              _vm.$set(data.item, "feeType", $$v);
            },
            expression: "data.item.feeType"
          }
        })];
      }
    }], null, true)
  }), _c('h3', [_vm._v(_vm._s(_vm.$t('fee.internationalFee')))]), _c('b-table', {
    attrs: {
      "fields": _vm.tableColumns,
      "striped": "",
      "bordered": "",
      "hover": "",
      "responsive": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "items": _vm.serviceFeeConfigs.filter(function (item) {
        return item.ticketType === 'INTERNATIONAL';
      })
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("fee.columns.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(airline)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex-center gap-1 font-weight-bolder"
        }, [_c('div', {
          staticClass: "d-flex-center",
          staticStyle: {
            "width": "50px"
          }
        }, [_c('IAmLogoAirline', {
          attrs: {
            "airline": item.airline.toUpperCase(),
            "height": 30
          }
        })], 1), _c('span', {
          staticClass: "flex-1 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$te("flight.airlines.".concat(item.airline.toUpperCase())) ? _vm.$t("flight.airlines.".concat(item.airline.toUpperCase())) : '') + " (" + _vm._s(_vm.$te("flight.sourceName.".concat(item.airline.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(item.airline.toUpperCase())) : item.airline.toUpperCase()) + ") ")])])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(data) {
        return [_c('IAmInputMoney', {
          staticClass: "flex-grow-1",
          staticStyle: {
            "min-width": "150px"
          },
          attrs: {
            "id": "amount-internationalFee",
            "value-money": data.item.amount,
            "disabled": !_vm.canUpdate,
            "placeholder": _vm.$t('fee.placeholder.amount')
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              return _vm.$set(data.item, "amount", $event);
            },
            "update:value-money": function updateValueMoney($event) {
              return _vm.$set(data.item, "amount", $event);
            }
          }
        })];
      }
    }, {
      key: "cell(feeType)",
      fn: function fn(data) {
        return [_c('v-select', {
          attrs: {
            "options": data.item.ticketType === 'INLAND' ? _vm.feeTypeOptions.filter(function (item) {
              return item.value === 'FLIGHT_ITINERARY';
            }) : _vm.feeTypeOptions,
            "reduce": function reduce(val) {
              return val.value;
            },
            "clearable": false,
            "disabled": !_vm.canUpdate,
            "append-to-body": true,
            "calculate-position": _vm.withPopper,
            "placeholder": _vm.$t('fee.placeholder.feeType')
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref5) {
              var label = _ref5.label;
              return [_c('span', {
                staticClass: "d-one-line"
              }, [_vm._v(" " + _vm._s(label) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(_ref6) {
              var label = _ref6.label;
              return [_c('span', {
                staticClass: "d-one-line"
              }, [_vm._v(" " + _vm._s(label) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: data.item.feeType,
            callback: function callback($$v) {
              _vm.$set(data.item, "feeType", $$v);
            },
            expression: "data.item.feeType"
          }
        })];
      }
    }], null, true)
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex-center"
  }, [_c('BButton', {
    staticClass: "font-medium-1 mr-1",
    attrs: {
      "variant": "outline-secondary",
      "pill": ""
    },
    on: {
      "click": _vm.handleExitModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('modal.back')) + " ")]), _vm.canUpdate ? _c('BButton', {
    staticClass: "btn-gradient font-medium-1",
    attrs: {
      "pill": ""
    },
    on: {
      "click": _vm.createEmployeeProfileHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.confirmChange')) + " ")]) : _vm._e()], 1)])], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }